.icon {
  display: inline-block;
  color: inherit;
  text-align: center;
  vertical-align: -.125rem; // Fix the position of icon
  fill: currentColor;
  &:not(.icon-c-s):not(.icon-custom-size) {
    @include icon-size($icon-size-base);

    &.icon-xl {
      @include icon-size($icon-size-xl);
    }

    &.icon-lg {
      @include icon-size($icon-size-lg);
    }

    &.icon-sm {
      @include icon-size($icon-size-sm);
    }
  }
}
